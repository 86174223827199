'use client'

import { useEffect, useState } from 'react'
import { Divider, Spacer, Text } from '@vinted/web-ui'

import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { useLocation } from '@marketplace-web/shared/browser'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { ContentLoader, SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { isIOS, parseOS } from '@marketplace-web/shared/utils'

import { getReferrer } from 'data/api'
import useGoogleTagManagerTrack from 'hooks/useGoogleTagManagerTrack'
import { FormName, GoogleTagManagerEvent, PageType } from 'constants/google'
import { ReferrerModel } from 'types/models'
import { transformReferrerDto } from 'data/transformers/referrer'
import useUserAgent from 'hooks/useUserAgent'

import Facebook from '../Facebook'
import Google from '../Google'
import Apple from '../Apple'
import BusinessRegistrationLink from './BusinessRegistrationsLink'
import SelectTypeFooter from './SelectTypeFooter'
import SelectTypeHeader from './SelectTypeHeader'
import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import { AuthView } from '../../constants'
import RandomUserSignup from '../RandomUserSignup'

const SelectType = () => {
  const isRandomUserSignupEnabled = useFeatureSwitch('next_random_user_signup')
  const { authView } = useAuthenticationContext()
  const isRegisterView = authView === AuthView.SelectTypeRegister
  const { googleAnalyticsTrack } = useGoogleTagManagerTrack()

  const { s: registrationType } = useLocation().searchParams
  const userAgent = useUserAgent()
  const isAppleDevice = isIOS(userAgent) || parseOS(userAgent) === 'MacOS'

  const [error, setError] = useState<string>()
  const [hasReferralError, setHasReferralError] = useState(false)
  const [referrer, setReferrer] = useState<ReferrerModel>()

  const translate = useTranslate('auth.select_type')

  const dividerSubheadlineWebAbTest = useAbTest(
    'promoting_google_and_apple_divider_subheadline_web',
  )
  useTrackAbTest(dividerSubheadlineWebAbTest)

  const isDividerSubheadlineWebAbTestEnabled = dividerSubheadlineWebAbTest?.variant === 'on'

  useEffect(() => {
    function pushGoogleAnalyticsEvent() {
      if (isRegisterView) {
        googleAnalyticsTrack({
          event: GoogleTagManagerEvent.modalView,
          customTagObject: { formName: FormName.Register, pageType: PageType.Registration },
        })
      } else {
        googleAnalyticsTrack({
          event: GoogleTagManagerEvent.modalView,
          customTagObject: { formName: FormName.Login, pageType: PageType.Login },
        })
      }
    }

    pushGoogleAnalyticsEvent()
  }, [isRegisterView, googleAnalyticsTrack])

  const shouldFetchReferrer = isRegisterView && !referrer && registrationType === 'referral'

  useEffect(() => {
    if (!shouldFetchReferrer) return

    const fetchReferrer = async () => {
      const response = await getReferrer()

      if ('errors' in response || !response.referrer_id) {
        setHasReferralError(true)

        return
      }

      setReferrer(transformReferrerDto(response))
    }

    fetchReferrer()
  }, [shouldFetchReferrer])

  if (!hasReferralError && shouldFetchReferrer) {
    return (
      <ContentLoader
        styling={ContentLoader.Styling.Wide}
        size={ContentLoader.Size.Large}
        testId="select-type-loader"
      />
    )
  }

  const renderDivider = () => {
    if (!isDividerSubheadlineWebAbTestEnabled) return null

    return (
      <div className="u-flexbox u-flex-direction-row u-align-items-center u-gap-large u-padding-small">
        <Divider />
        <Text
          as="span"
          text={translate('divider.or')}
          type={Text.Type.Caption}
          alignment={Text.Alignment.Center}
          theme="muted"
        />
        <Divider />
      </div>
    )
  }

  const renderSocialRegisterSubheadline = () => {
    if (!isDividerSubheadlineWebAbTestEnabled || !isRegisterView) return null

    return (
      <Text
        as="h2"
        text={translate(
          isAppleDevice ? 'register.subheadline_apple' : 'register.subheadline_google',
        )}
        type={Text.Type.Title}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
        theme="amplified"
      />
    )
  }

  const renderSocialLogins = () => {
    if (isAppleDevice) {
      return (
        <SeparatedList separator={<Spacer />}>
          <Apple setError={setError} />
          {renderDivider()}
          <Google setError={setError} />
          <Facebook setError={setError} />
          {isRandomUserSignupEnabled && isRegisterView && <RandomUserSignup />}
        </SeparatedList>
      )
    }

    return (
      <SeparatedList separator={<Spacer />}>
        <Google setError={setError} />
        {renderDivider()}
        <Apple setError={setError} />
        <Facebook setError={setError} />
        {isRandomUserSignupEnabled && isRegisterView && <RandomUserSignup />}
      </SeparatedList>
    )
  }

  return (
    <>
      <div className="u-ui-padding-horizontal-large u-ui-padding-bottom-x-large">
        <SelectTypeHeader
          referrer={referrer}
          isDividerSubheadlineWebAbTestEnabled={isDividerSubheadlineWebAbTestEnabled}
        />
        <Spacer />
        {error && (
          <Text
            as="span"
            text={error}
            theme="warning"
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            html
          />
        )}
        {renderSocialRegisterSubheadline()}
        <Spacer size={Spacer.Size.Large} />
        {renderSocialLogins()}
        {isDividerSubheadlineWebAbTestEnabled ? <Spacer /> : <Spacer size={Spacer.Size.XLarge} />}

        <SelectTypeFooter
          isDividerSubheadlineWebAbTestEnabled={isDividerSubheadlineWebAbTestEnabled}
        />
      </div>
      {isRegisterView && <BusinessRegistrationLink />}
    </>
  )
}

export default SelectType
